import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { GooglemapsAddress } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { adminUrls } from 'utils/urls';

import { LEADS_KEY } from './useLeads';

import { LEAD_CLIENTS_KEY } from '../../Leads/api/useLeadClients';
import { CampaignInfos } from '../types';

export type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  googleMapsAddress: GooglemapsAddress;
  source?: string;
  campaign?: CampaignInfos | string;
  emailCommercial?: string;
  title?: string;
  budget: string;
  surface: number;
  description?: string;
  boucheAOreille?: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  };
  shouldAttributeLeadManually: boolean;
  shouldSendCreationEmail: boolean;
  chaleurDeal?: string;
  subcampaign?: CampaignInfos;
  parrainPipedriveDealId?: number;
  parrainOwnerEmail?: string;
};

export const useCreateLead = (): ((payload: FormData) => Promise<void>) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (payload: FormData) => {
      const body =
        typeof payload.campaign === 'string'
          ? payload
          : {
              ...payload,
              campaign: payload.campaign?.attributionName,
              subcampaign: payload.subcampaign?.attributionName,
              internalAccountManager:
                payload.subcampaign?.internalAccountManager ||
                payload.campaign?.internalAccountManager,
            };
      return request(`${APP_CONFIG.apiURL}/admin/leads`, {
        method: 'POST',
        body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [LEADS_KEY] });
      await queryClient.invalidateQueries({ queryKey: [LEAD_CLIENTS_KEY] });

      navigate(adminUrls.leadsUrl);
    },
  });

  return mutateAsync;
};
