import React from 'react';

import LeftChevron from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ProgressBar } from '@travauxlib/shared/src/components/DesignSystem/components/ProgressBar';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';

import { calculatePercentageAvancement } from '../utils/math';

type Props = {
  chantier: Chantier;
};

export const ChantierHeader: React.FC<Props> = ({ chantier }) => {
  const progressValue = calculatePercentageAvancement(
    chantier.totalPaiements,
    chantier.montantTotalTTC,
  );

  return (
    <div>
      <div className="mt-md flex justify-between items-center flex-col sm-desktop:flex-row">
        <ButtonLink
          size="sm"
          className="self-start"
          leftIcon={<LeftChevron />}
          to=".."
          variant="tertiary"
        >
          Retour
        </ButtonLink>
        <div className="w-full sm-desktop:w-[28rem]">
          <div className="text-ds-b2 flex justify-between pb-xxs">
            <span>Avancement chantier</span>
            <span className="font-bold">{progressValue.toFixed(0)}% </span>
          </div>
          <ProgressBar value={progressValue} />
        </div>
      </div>
    </div>
  );
};
