export const calculatePercentageAvancement = (numerator: number, denominator: number): number => {
  if (numerator === 0 && denominator === 0) {
    return 100;
  }

  if (denominator === 0) {
    return 0; // Return 0% if the total is 0 to avoid division by zero
  }

  return Math.floor((numerator / denominator) * 100);
};
