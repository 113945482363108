import React from 'react';

import { useForm } from 'react-final-form';

import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import {
  required,
  requiredValidEmail,
  validatePhoneNumber,
} from '@travauxlib/shared/src/utils/form';

import { CampaignInfos } from 'features/DashboardCDP/types';
import { usePartners } from 'features/Partners/api/usePartners';
import { PartnerAgency, PartnerOrganization } from 'types';
import {
  boucheAOreille,
  clubProSourceOptions,
  marketplaceSourceOptions,
  partnerOffline,
  partnerOnline,
  recommandationClient,
  recommandationInterne,
  salon,
  salonOptions,
} from 'utils/constants/acquisition';

import { CampaignAndSubCampaign } from './CampaignAndSubCampaign';

const filterBySource = (partners: PartnerOrganization[], source: string): CampaignInfos[] =>
  partners
    .filter(partner => partner.agencies.some(agency => agency.source === source))
    .map(partner => ({
      attributionName: partner.attributionName,
      internalAccountManager: partner.internalAccountManager,
    }));

const filterBySourceAndCampaign = (
  partners: PartnerOrganization[],
  source: string,
  campaign?: string,
): PartnerAgency[] =>
  partners
    .filter(partner => partner.attributionName === campaign)
    .reduce((acc, partner) => {
      const subCampaigns = partner.agencies.filter(agency => agency.source === source);
      return [...acc, ...subCampaigns];
    }, []);

type Props = {
  source?: string;
  campaign?: string;
  isForClubProLead?: boolean;
};

export const Source: React.FC<Props> = ({ source, campaign, isForClubProLead }) => {
  const { partners } = usePartners();

  const form = useForm();

  return (
    <>
      <div className="col-span-full">
        <DropdownField
          id="source"
          label="Source"
          name="source"
          options={isForClubProLead ? clubProSourceOptions : marketplaceSourceOptions}
          validate={required}
        />
      </div>

      {(source === partnerOffline.value || source === partnerOnline.value) && (
        <CampaignAndSubCampaign
          partnerCampaigns={filterBySource(partners, source)}
          partnerSubCampaigns={filterBySourceAndCampaign(partners, source, campaign)}
        />
      )}

      {source === boucheAOreille.value && (
        <>
          <div className="col-span-full sm-desktop:col-span-6">
            <InputField
              name="boucheAOreille.firstName"
              id="boucheAOreille.firstName"
              label="Prénom de la source"
              validate={required}
            />
          </div>
          <div className="col-span-full sm-desktop:col-span-6">
            <InputField
              name="boucheAOreille.lastName"
              id="boucheAOreille.lastName"
              label="Nom de la source"
              validate={required}
            />
          </div>
          <div className="col-span-full sm-desktop:col-span-6">
            <InputField
              name="boucheAOreille.phoneNumber"
              id="boucheAOreille.phoneNumber"
              label="Téléphone de la source"
              validate={validatePhoneNumber}
            />
          </div>
          <div className="col-span-full sm-desktop:col-span-6">
            <InputField
              name="campaign"
              id="campaign"
              label="Email de la source"
              onChange={value => {
                form.change('campaign', value);
                form.change('boucheAOreille.email', value);
              }}
              validate={requiredValidEmail}
            />
          </div>
        </>
      )}

      {(source === recommandationInterne.value || source === recommandationClient.value) && (
        <div className="col-span-full sm-desktop:col-span-6">
          <InputField
            name="campaign"
            id="campaign"
            label="Copain de qui ?"
            validate={source === recommandationInterne.value ? required : undefined}
          />
        </div>
      )}

      {source === salon.value && (
        <div className="col-span-full sm-desktop:col-span-6">
          <DropdownField
            name="campaign"
            id="campaign"
            label="Nom du salon"
            options={salonOptions}
            validate={required}
          />
        </div>
      )}
    </>
  );
};
